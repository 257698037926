import Cookies from 'js-cookie'

export class User {
    public companyId:number
    public features: Set<string>

    public constructor(companyId?:number) {
        this.companyId = companyId ? companyId : this.getCompanyIdFromCookie()
        this.features = new Set();
    }
    public getCompanyIdFromCookie = (): number => {
        const maybePublisherId = parseInt(Cookies.get('jsCompanyId') || "")
        return isNaN(maybePublisherId) ? 1070179 : maybePublisherId
    }

    public hasAdditionalOnLoadFeatureAccess = (): boolean => {
        return this.features.has("PUB_TAG_ON_LOAD_FEATURES")
    }

}
