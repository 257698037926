export interface IConfig {
    ptApiUrl: string,
    loginRedirectUrl: string,
    pendoUrl?: string,
    userUrl: string,
    redirectBackToCjUrl: string,
    promotionalPropertiesUrl: string,
    advertisersUrl: string
}

export const getConfigFromEnv = (env: string | undefined): IConfig => {

    if (env === "prod") {
        return {
            ptApiUrl: "https://pubtag.api.cj.com",
            loginRedirectUrl: "https://members.cj.com/member/login",
            pendoUrl: "https://members.cj.com/member/contactSupport.cj?id=kA11C0000005oT",
            userUrl: "https://members.cj.com/affapi/oauth/user",
            redirectBackToCjUrl: "https://members.cj.com/member/publisher/home.do",
            promotionalPropertiesUrl: "https://accounts.api.cj.com/graphql",
            advertisersUrl: "https://members.cj.com/affapi/publishers"
        }
    } else if (env === "lab") {
        return {
            ptApiUrl: "https://ptapi.ads-staging.cjpowered.com",
            loginRedirectUrl: "https://member103.lab.cj.com/member/login",
            userUrl: "https://member103.lab.cj.com/affapi/oauth/user",
            redirectBackToCjUrl: "https://member103.lab.cj.com/member/publisher/home.do",
            promotionalPropertiesUrl: "https://accounts.api.cj.com/graphql",
            advertisersUrl: "https://member103.lab.cj.com/affapi/publishers"
        }
    } else {
        return {
            ptApiUrl: "https://ptapi.ads-staging.cjpowered.com",
            loginRedirectUrl: "https://member103.lab.cj.com/member/login/#/?appName=pubtagLocal&path=/manage",
            userUrl: "https://members.cj.com/affapi/oauth/user",
            redirectBackToCjUrl: "https://member103.lab.cj.com/member/publisher/home.do",
            promotionalPropertiesUrl: "https://accounts.api.cj.com/graphql",
            advertisersUrl: "https://member103.lab.cj.com/affapi/publishers"
        }
    }
}
