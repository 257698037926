import React from "react"
import {useIntl} from "../../utils/intl"
import content from "../../utils/content"
import {CreatableSelect} from "@cjdev-internal/visual-stack-x/CreatableSelect"
import {Box} from "@cjdev-internal/visual-stack-x/Box"
import {useAdvertisers} from '../../hooks'
import {IAdvertiser, IAdvertiserOption, IAdvertiserSelectorProps} from "../../utils/types"

const AdvertiserSelector = (props: IAdvertiserSelectorProps): JSX.Element => {

    const propsCriteria = {publisherId: props.publisherId, joinedOrAll: props.joinedOrAll}

    const { data, error, isError } = useAdvertisers(propsCriteria)

    const intl = useIntl()

    const updateAdvertisers = (advertiserOptions: IAdvertiserOption[]): void => {
        props.saveAdvertisers(advertiserOptions?.map(item => Number(item.value)))
    }

    if (isError) {
        return <span>{intl.formatMessage(content.tagModalError)}: {error}</span>
    }

    const fetchedAdvertiserList = data ? data.advertisers : []

    const advertiserList : IAdvertiserOption[] = fetchedAdvertiserList.map((a: IAdvertiser) => {
        return {
            value: Number(a.cid),
            label: a.websiteName,
        }
    })
    const savedAdvertisersSelection : IAdvertiserOption[] = advertiserList.filter((item: IAdvertiserOption) => props.savedAdvertisers?.includes(item.value))

    return (
        <Box paddingTop="large" direction="column">
            <Box direction="row">
                <label>{intl.formatMessage(content.modalTagExcludeAdvertisersLabel)}</label>
            </Box>
            <CreatableSelect
                isMulti
                className="exclude-advertisers-selector"
                placeholder={intl.formatMessage(content.selectFromList)}
                options={advertiserList}
                value={savedAdvertisersSelection}
                onChange={updateAdvertisers}
            />
        </Box>
    )
}

export default AdvertiserSelector
