import AuthService from "../AuthService"

type AuthenticatedFetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>

export const getAuthenticatedFetch = (authService: AuthService, fetch: WindowOrWorkerGlobalScope['fetch']): AuthenticatedFetch => {
    return async (input: RequestInfo, init?: RequestInit) => {
        const authToken = await authService.getBearerToken();
        const headers = Object.assign({
            Authorization: `Bearer ${authToken}`
        }, init?.headers);
        const authHeaderInit: RequestInit = Object.assign({
            mode: 'cors',
            credentials: "include",
        }, init);
        authHeaderInit.headers = headers;

        return fetch(input, authHeaderInit);
    }
}
