import React, {useEffect, useState} from "react"
import * as R from "ramda"
import {Redirect, Route} from "react-router-dom"
import {getBearerToken} from "../shared/AuthService";

//@ts-ignore unsure where the Component type is coming from and if it refers to the same Component in line 23
//eslint-disable-next-line react/prop-types, @typescript-eslint/naming-convention
const PrivateRoute = ({component: Component, ...rest}): JSX.Element => {
    const [isAuthenticatedLocal, setIsAuthenticatedLocal] = useState(false)
    useEffect(() => {
        (async () => {
            const maybeToken = await getBearerToken() //token or null
            const isAuthenticatedFromAuth = !R.isNil(maybeToken)
            setIsAuthenticatedLocal(isAuthenticatedFromAuth)
        })().catch((e)=>{
            console.error(e)
        })
    }, [])

    return !isAuthenticatedLocal ? <div/> :
    <Route {...rest} render={(props) => (
        isAuthenticatedLocal
            ? <Component {...props} />
            : <Redirect to='/login'/>
    )}/>
}

export default PrivateRoute
