import {OnloadFeatures, OnLoadPubTag} from "@pubtag/tag-server";
import {Box} from "@cjdev-internal/visual-stack-x/Box";
import {Text} from "@cjdev-internal/visual-stack-x/Text";
import {useIntl} from "../../utils/intl";
import content from "../../utils/content";
import {Field, FieldContent, ChoiceInput} from "@cjdev-internal/visual-stack-x/Form";
import React, {FC, useState} from "react";
import {IAdvertiserSelectorProps, IPromoPropertiesProps} from "../../utils/types";
import {TextField} from "@cjdev-internal/visual-stack-x/Form"

export const defaultOnloadFeatures: OnloadFeatures = {
    bounceless: true, consentModule: true,
    onLoad: true,
    pageBasedImpressionTracking: false,
    deepLinkAutomation: false,
    sids: "",
    dlaMyAdvertisersOnly: false,
    dlaExcludedAdvertisers: []
}

export interface IOnLoadTagSectionProps {
    pubTag: OnLoadPubTag,
    setPubTag: (tag: OnLoadPubTag) => void,
    advertiserSelectorComponent: FC<IAdvertiserSelectorProps>,
    promotionalPropertiesComponent: FC<IPromoPropertiesProps>
}

const OnLoadTagSection = (props: IOnLoadTagSectionProps): JSX.Element => {

    const [localPubTagState, setLocalPubTagState] = useState(props.pubTag)

    const intl = useIntl()

    const updatePubTag = (onLoadFeatures: Partial<OnloadFeatures>): void => {

        const updatedPubTag: OnLoadPubTag = {
            ...(props.pubTag),
            features: {
                ...defaultOnloadFeatures,
                ...(props.pubTag.features),
                ...(localPubTagState.features),
                ...onLoadFeatures
            }
        }
        setLocalPubTagState(updatedPubTag)
        props.setPubTag(updatedPubTag)
    }

    const publisherId = props.pubTag.publisherId

    const setDlaMyAdvertisersOnly = (e: React.MouseEvent<HTMLButtonElement>): void => {
        const isMyAdvertisersOnlySelected = e.currentTarget.value === 'true'
        updatePubTag({dlaMyAdvertisersOnly: isMyAdvertisersOnlySelected})
    }

    const updateExcludedAdvertisers = (excludedAdvertisers: number[]): void => {
        updatePubTag({dlaExcludedAdvertisers: excludedAdvertisers || []})
    }

    const updatePromoProperty = (promotionalPropertyId: number): void => {
        updatePubTag({promotionalPropertyId: promotionalPropertyId})
    }

    return (
        <>
            <Box paddingTop="large" paddingBottom="small" direction="column" >
                <Text aria-label="modalTagOptionsTitle" size={16} weight="medium">{intl.formatMessage(content.modalTagOptionsTitle)}</Text>
            </Box>

            <Box direction="column">
                <Box className="" direction="row">
                    <ChoiceInput
                        name="pageBasedTracking"
                        aria-label="pageBasedTracking"
                        type="checkbox"
                        checked={localPubTagState.features.pageBasedImpressionTracking}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updatePubTag({pageBasedImpressionTracking: e.target.checked})}
                        label={<Text type="bold">{intl.formatMessage(content.modalTagPageBasedTrackingOption)}</Text>}
                    />
                </Box>
                <Box className="" direction="row">
                    <ChoiceInput
                        name="deepLinkAutomation"
                        aria-label="deepLinkAutomation"
                        type="checkbox"
                        checked={localPubTagState.features.deepLinkAutomation}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updatePubTag({deepLinkAutomation: e.target.checked})}
                        label={<Text aria-label="modalTagDeepLinkOption" type="bold">{intl.formatMessage(content.modalTagDeepLinkOption)}</Text>}
                    />
                </Box>
            </Box>

            {(localPubTagState.features.deepLinkAutomation || localPubTagState.features.pageBasedImpressionTracking) ?
                <>
                    <Box paddingTop="large" paddingBottom="small" direction="column" >
                        <Text aria-label="modalSelectedFeatureOptions" size={16} weight="medium">{intl.formatMessage(content.modalSelectedFeatureOptions)}</Text>
                    </Box>
                    <props.promotionalPropertiesComponent
                        publisherId={publisherId}
                        promotionalPropertyId={localPubTagState.features.promotionalPropertyId}
                        updatePromoProperty={updatePromoProperty}
                    />
                </> : null
            }

            {(localPubTagState.features.deepLinkAutomation) ?
                <>
                    <Box paddingTop="small">
                        <Field
                            label={
                                <>
                                    <Text aria-label="modalTagLinksToModifyText">{intl.formatMessage(content.modalTagLinksToModifyText)}</Text>
                                    <span className="form-group-required-sign">&nbsp;*</span>
                                </>
                            }
                        >
                            <FieldContent>
                                <ChoiceInput
                                    name="dlaMyAdvertisersOnly"
                                    aria-label="dlaMyAdvertisersOnlyFalse"
                                    value="false"
                                    checked={!(localPubTagState.features.dlaMyAdvertisersOnly)}
                                    // @ts-ignore
                                    onClick={setDlaMyAdvertisersOnly}
                                    onChange={() => {}}
                                    label={<Text aria-label="modalTagAllOption">{intl.formatMessage(content.modalTagAllOption)}</Text>}
                                />
                                <ChoiceInput
                                    name="dlaMyAdvertisersOnlyTrue"
                                    aria-label="dlaMyAdvertisersOnlyTrue"
                                    value="true"
                                    checked={localPubTagState.features.dlaMyAdvertisersOnly}
                                    // @ts-ignore
                                    onClick={setDlaMyAdvertisersOnly}
                                    onChange={() => {}}
                                    label={<Text aria-label="modalTagJoinedOption">{intl.formatMessage(content.modalTagJoinedOption)}</Text>}
                                />
                            </FieldContent>
                        </Field>
                    </Box>

                    <props.advertiserSelectorComponent
                        publisherId={publisherId}
                        joinedOrAll={localPubTagState.features.dlaMyAdvertisersOnly ? 'joined' : 'all'}
                        saveAdvertisers={updateExcludedAdvertisers}
                        savedAdvertisers={props.pubTag.features.dlaExcludedAdvertisers}
                    />

                    <Box paddingTop="medium" direction="column">
                        <TextField
                            value={localPubTagState.features.sids}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updatePubTag({sids: e.target.value})}
                            label={intl.formatMessage(content.modalTagSidLabel)}
                            placeholder={intl.formatMessage(content.modalTagSidPlaceholder)}
                            aria-label="modalTagSidInput"
                        />
                    </Box>
                </> : null
            }
        </>
    )
}

export default OnLoadTagSection
