import {LoadingAnimation as VsLoadingAnimation} from "@cjdev-internal/visual-stack-x/LoadingAnimation";
import {useIntl} from "../utils/intl";
import content from "../utils/content";
import React, {FC} from "react";
import styled, {StyledComponent} from "styled-components";

const StyledLoadingAnimation: StyledComponent<"div", object> = styled.div`
    text-align: center;
`

const Loading: FC = () => {
    const intl = useIntl()
    return <StyledLoadingAnimation>
        <VsLoadingAnimation
            loadingMessage={intl.formatMessage(content.tagListLoading)}
        />
    </StyledLoadingAnimation>;
}

export default Loading
