import React, {useState} from 'react'
import styled, {StyledComponent} from "styled-components"

import {Modal, ModalProps } from '@cjdev-internal/visual-stack-x/Modal'
import {Button} from "@cjdev-internal/visual-stack-x/Button"
import {Box} from "@cjdev-internal/visual-stack-x/Box"

import {useIntl} from "../utils/intl"
import content from "../utils/content"

const StyledButton:StyledComponent<typeof Button,object> = styled(Button)`
    margin-right: 1.0em;
`

export interface IGenerateCodeProps {
    tagId: number | undefined,
    publisherId: number | undefined,
    closeModal: () => void
}

const GenerateCodeModal = (props: IGenerateCodeProps): JSX.Element => {

    const [copied, setCopied] = useState(false)
    const intl = useIntl()
    const copyCodeToClipboard = (): void => {
        const el: HTMLTextAreaElement | null = document.querySelector('#generated-code')
        if (el) {
            el.select()
            document.execCommand('copy')
            setCopied(true)
        } else {
            console.error("ERROR: Somehow there was no code to copy. What did you do?")
        }
    }
    const closeButtonName = intl.formatMessage(content.generateCodeModalClose)
    const copyButtonName = copied
        ? intl.formatMessage(content.generateCodeModalCopied)
        : intl.formatMessage(content.generateCodeModalCopy)

    const modalProps: ModalProps = {
        onBackgroundClick: props.closeModal,
        onEscapeKeyUp: props.closeModal,
        headerTitle: intl.formatMessage(content.generateCodeModalTitle),
        footer:
            <>
                <StyledButton type="tertiary" onClick={() => props.closeModal()}>{closeButtonName}</StyledButton>
                <Button type="primary" onClick={copyCodeToClipboard}>{copyButtonName}</Button>
            </>
    }

    return (
        <div>
            <Modal {...modalProps}>
                <Box paddingTop="medium" direction="column">
                    <label aria-label="script label" className="generate-code-script">{intl.formatMessage(content.generateCodeModalLabel)}</label>
                    <textarea
                        id="generated-code"
                        value={`<script type="text/javascript" src="https://www.p.zjptg.com/tag/${props.publisherId}/${props.tagId}"></script>`}
                        onChange={() => {}}
                        style={{ height: '6em', width: '100%', maxWidth: '100%', minWidth: '100%'}}
                    />
                </Box>
            </Modal>
        </div>
    )
}

export default GenerateCodeModal;