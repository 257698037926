import { defineMessages } from "react-intl"

export default defineMessages({
    appTitle: {
        id: "pubtag.app.title",
    },
    appDescription: {
        id: "pubtag.app.description",
    },
    tagListTitle: {
        id: "pubtag.list.title",
    },
    tagListTagName: {
        id: "pubtag.list.tagName",
    },
    tagListHeaderTagId: {
        id: "pubtag.list.header.tagId",
    },
    tagListHeaderLastUpdated: {
        id: "pubtag.list.header.lastUpdated",
    },
    tagListHeaderStatus: {
        id: "pubtag.list.header.status",
    },
    tagListHeaderTagType: {
        id: "pubtag.list.header.tagType",
    },
    tagListHeaderActions: {
        id: "pubtag.list.header.actions",
    },
    tagListActionsDropdownSelect: {
        id: "pubtag.list.actions.dropdown.select",
    },
    tagListActionsGenerateCode: {
        id: "pubtag.list.actions.dropdown.generateCode",
    },
    tagListActionsEdit: {
        id: "pubtag.list.actions.dropdown.edit",
    },
    tagListActionsArchive: {
        id: "pubtag.list.actions.dropdown.archive",
    },
    tagListCreateTag: {
        id: "pubtag.list.createTag",
    },
    tagLearnMore: {
        id: "pubtag.modal.learnMore",
    },
    tagExample: {
        id: "pubtag.modal.example",
    },
    tagBlankSlateMainDescription: {
        id: "pubtag.blankSlate.mainDescription",
    },
    tagBlankSlateSubDescription: {
        id: "pubtag.blankSlate.subDescription",
    },
    tagOnClickExampleDescription: {
        id: "pubtag.modal.onClick.example.description",
    },
    tagOnLoadExampleDescription: {
        id: "pubtag.modal.onLoad.example.description",
    },
    tagModalSave: {
        id: "pubtag.modal.save",
    },
    tagModalNext: {
        id: "pubtag.modal.next",
    },
    tagModalCancel: {
        id: "pubtag.modal.cancel",
    },
    tagModalCreate: {
        id: "pubtag.modal.create",
    },
    tagModalBack: {
        id: "pubtag.modal.back",
    },
    tagModalLoading: {
        id: "pubtag.modal.loading",
    },
    tagModalError: {
        id: "pubtag.modal.error",
    },
    selectFromList: {
        id: "pubtag.modal.selectFromListPlaceholder",
    },
    tagListModalTitle: {
        id: "pubtag.list.modal.title",
    },
    tagModalChooseTagTitle: {
        id: "pubtag.modal.chooseTagTitle",
    },
    tagModalChooseTagDescription: {
        id: "pubtag.modal.chooseTagDescription",
    },
    tagModalOnClickDescription: {
        id: "pubtag.modal.onClick.description"
    },
    tagModalOnLoadDescription: {
        id: "pubtag.modal.onLoad.description"
    },
    tagListModalWebsite: {
        id: "pubtag.list.modal.website",
    },
    tagListModalCreateMessage: {
        id: "pubtag.list.modal.createSuccess",
    },
    tagListModalGenerateCode: {
        id: "pubtag.list.modal.generateCode",
    },
    tagListLoading: {
        id: "pubtag.list.loading",
    },
    generateCodeModalTitle: {
        id: "pubtag.list.generateCodeModal.title",
    },
    generateCodeModalLabel: {
        id: "pubtag.list.generateCodeModal.label",
    },
    generateCodeModalClose: {
        id: "pubtag.list.generateCodeModal.close",
    },
    generateCodeModalCopy: {
        id: "pubtag.list.generateCodeModal.copy",
    },
    generateCodeModalCopied: {
        id: "pubtag.list.generateCodeModal.copied",
    },
    archivePublisherTag: {
        id: "pubtag.list.archivemodal.title",
    },
    confirm: {
        id: "pubtag.list.archivemodal.confirm",
    },
    archiveModalWarning: {
        id: "pubtag.list.archivemodal.warning",
    },
    editPublisherTag: {
        id: "pubtag.editModal.editPublisherTag",
    },
    editPublisherTagSuccess: {
        id: "pubtag.editModal.saveSuccess",
    },
    active : {
        id: "pubtag.list.status.active",
    },
    archived : {
        id: "pubtag.list.status.archived",
    },
    onLoad : {
        id: "pubtag.list.tagType.onLoad",
    },
    onClick : {
        id: "pubtag.list.tagType.onClick",
    },
    archivePublisherTagSuccess: {
        id: "pubtag.archiveModal.saveSuccess",
    },
    backToCjam : {
        id: "pubtag.app.backToCjam",
    },
    navigationChoosePublisherAccount : {
        id: "pubtag.navigation.choosePublisherAccount",
    },
    navigationPublisherAccountNotAuthorized : {
        id: "pubtag.navigation.publisherAccountNotAuthorized",
    },
    pendoFeedback : {
        id: "pubtag.app.pendoFeedback",
    },
    modalSaveError: {
        id: "pubtag.modal.saveError",
    },
    modalTagTypeLabel: {
        id:"pubtag.modal.tagTypeLabel",
    },
    modalTagNameInfo: {
        id: "pubtag.modal.tagNameInfo"
    },
    modalTagTypeOnClick: {
        id:"pubtag.modal.onClick.name",
    },
    modalTagTypeOnLoad: {
        id:"pubtag.modal.onLoad.name",
    },
    modalTagOnClickName: {
        id: "pubtag.modal.onClick.tagNameLabel"
    },
    modalTagOnLoadName: {
        id: "pubtag.modal.onLoad.tagNameLabel"
    },
    modalTagOptionsTitle: {
        id: "pubtag.modal.onLoad.featuresTitle"
    },
    modalSelectedFeatureOptions: {
        id: "pubtag.modal.onLoad.selectedFeatureOptionsTitle"
    },
    modalTagPromoListLabel: {
        id: "pubtag.modal.onLoad.promoListLabel"
    },
    modalTagPageBasedTrackingOption: {
        id: "pubtag.modal.onLoad.pageBasedTrackingOption"
    },
    modalTagDeepLinkOption: {
        id: "pubtag.modal.onLoad.deepLinkOption"
    },
    modalTagLinksToModifyText: {
        id: "pubtag.modal.onLoad.links"
    },
    modalTagAllOption: {
        id: "pubtag.modal.onLoad.allOption"
    },
    modalTagJoinedOption: {
        id: "pubtag.modal.onLoad.joinedOption"
    },
    modalTagExcludeAdvertisersLabel: {
        id: "pubtag.modal.onLoad.excludeAdvertisersLabel"
    },
    modalTagSidLabel: {
        id: "pubtag.modal.onLoad.sidLabel"
    },
    modalTagSidPlaceholder: {
        id: "pubtag.modal.onLoad.sidPlaceholder"
    }
})
