import {ApolloProvider} from "@apollo/client"
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {accountsClient as apolloAccountsClient} from "../utils/clients"
import React from 'react'
import {IntlProvider} from "react-intl";

interface IProviderProps {
    children: JSX.Element
}

export const Provider = ({children}: IProviderProps): JSX.Element => {
    const accountsClient = apolloAccountsClient
    const queryClient = new QueryClient()

    return (
        <IntlProvider locale={"en"}>
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false}/>
                <ApolloProvider client={accountsClient}>
                    {children}
                </ApolloProvider>
            </QueryClientProvider>
        </IntlProvider>
    )
}
