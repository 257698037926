import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState
} from 'react'
import * as R from "ramda"
import styled, {StyledComponent} from "styled-components"

import {ActionMenuClickEvent, ActionMenuOptionProps, DataGrid} from "@cjdev-internal/visual-stack-x/DataGrid"
import {BlankSlate, Description, PrimaryActionButton} from '@cjdev-internal/visual-stack-x/BlankSlate'
import {ModalProps, useModal} from "@cjdev-internal/visual-stack-x/Modal"
import {Text} from "@cjdev-internal/visual-stack-x/Text"
import {Box} from "@cjdev-internal/visual-stack-x/Box"
import {useToast} from "@cjdev-internal/visual-stack-x/Toast"
import {useRandomGuid} from "@cjdev-internal/visual-stack-x/hooks"

import {BasePubTag, FullPubTagWithOptionalFields, NewPubTag, PubTag} from '@pubtag/tag-server'
import CreateTagModal from "../Components/CreateTagModal"
import EditTagModal from "../Components/EditTagModal"
import GenerateCodeModal from "../Components/GenerateCodeModal"
import Loading from "../Components/Loading"
import OnLoadTagSection from "../Components/onLoad/OnLoadTagSection"
import {User} from "../shared/User"
import {IApi, ICreateTagFields} from "../utils/api"
import {useIntl} from "../utils/intl"
import content from "../utils/content"


interface ITagTableProps {
    tags: PubTag[]
    user: User
    saveTag: (tag: FullPubTagWithOptionalFields) => void
    // eslint-disable-next-line @rushstack/no-new-null
    openModal: (context: JSX.Element | ModalProps | null) => void
    closeModal: () => void
    mount: JSX.Element
    title: string
    // hacky property to set the proper height of the grid's ancestor
    refreshHeight: () => void
}

export interface IPubTagGridItem {
    tagId: number
    name: string
    dateLastUpdated: string
    status: string
    type: string
    pubTag: FullPubTagWithOptionalFields
}

const LinkDiv: StyledComponent<"div", object> = styled.div`	    
    a:hover { cursor: pointer; }
`

const UnderlinedText: StyledComponent<typeof Text, object> = styled(Text)`
    text-decoration: underline;
    margin-left: 0.2em;   
`

export const TagTable: (props: ITagTableProps) => JSX.Element = (props: ITagTableProps) => {
    const intl = useIntl()

    const openEditModal = (e: ActionMenuClickEvent<IPubTagGridItem>): void => props.openModal(
        <EditTagModal onLoadTagSection={OnLoadTagSection} {...props} row={e.rowData}></EditTagModal>
    )

    const openGenerateCodeModal = (e: ActionMenuClickEvent<IPubTagGridItem>): void => props.openModal(
        <GenerateCodeModal
            tagId={e.rowData.tagId}
            publisherId={e.rowData.pubTag.publisherId}
            closeModal={props.closeModal}
        />
    )

    const rowActions: ActionMenuOptionProps<IPubTagGridItem>[] = [
        {
            label: intl.formatMessage(content.tagListActionsGenerateCode),
            onClick: (e) => openGenerateCodeModal(e)
        },
        {
            label: intl.formatMessage(content.tagListActionsEdit),
            onClick: (e) => openEditModal(e)
        },
    ]

    const tagsArray: IPubTagGridItem[] = props.tags.map((tag) => {
        const dateLastUpdatedString = new Date(tag.dateLastUpdated).toLocaleString()
        const status = tag.isArchived ? intl.formatMessage(content.archived) : intl.formatMessage(content.active)
        const tagType = tag.features.onLoad ? intl.formatMessage(content.onLoad) : intl.formatMessage(content.onClick)
        return {
            tagId: tag.tagId,
            name: tag.name,
            dateLastUpdated: dateLastUpdatedString,
            status: status,
            type: tagType,
            pubTag: tag
        }
    })

    return (
        <div style={{flexGrow: 1}}>
            <div data-testid="dataGridDiv" style={{height: "100%"}}>
                {props.mount}
                <DataGrid<IPubTagGridItem>
                    title={props.title}
                    columnDefs={[
                        {
                            field: "tagId",
                            headerName: intl.formatMessage(content.tagListHeaderTagId),
                            minWidth: 100,
                            maxWidth: 100
                        },
                        {field: "name", headerName: intl.formatMessage(content.tagListTagName), minWidth: 200, flex: 1},
                        {
                            field: "dateLastUpdated",
                            headerName: intl.formatMessage(content.tagListHeaderLastUpdated),
                            minWidth: 200,
                            flex: 0.5
                        },
                        { field: "status", headerName: intl.formatMessage(content.tagListHeaderStatus), minWidth: 100, maxWidth: 100 },
                        { field: "type", headerName: intl.formatMessage(content.tagListHeaderTagType), minWidth: 100, maxWidth: 100 },
                    ]}
                    getRowId={(pt: IPubTagGridItem) => pt.tagId}
                    rowData={tagsArray}
                    rowActions={rowActions}
                    onGridSizeChanged={p => p.api.sizeColumnsToFit()}
                    onGridReady={() => {
                        props.refreshHeight()
                    }}
                    // onFirstDataRendered={p => {
                    //     setTimeout(p.api.sizeColumnsToFit, 0)
                    // }}
                    onFirstDataRendered={p => p.api.sizeColumnsToFit()}
                />
            </div>
        </div>
    )
}

const loadData = async (fetchTagsFromApi: () => Promise<FullPubTagWithOptionalFields[]>, setTagsState: React.Dispatch<React.SetStateAction<PubTag[] | undefined>>, setErrorState: React.Dispatch<React.SetStateAction<string>>, logMessage: (message: string) => Promise<void>): Promise<void> => {
    let allTags: PubTag[] = []
    try {
        allTags = await fetchTagsFromApi() as PubTag[]
        setTagsState(allTags)
    } catch (e) {
        if (e instanceof Error) {
            await logMessage(e.message)
            setErrorState(e.message)
        }
    }
}

const AlertStyleOverrides: StyledComponent<"div", object> = styled.div`	
    top: 80%;
    max-width: 30em;
`

const CreateButtonAboveTableStyleOverrides: StyledComponent<"div", object> = styled.div`
    .pt-create-tag-button {
        margin-bottom: 24px;
        margin-left: 0px;
        margin-right: 0px;
    }
`

export type CreateButtonRefHandler = {
    createButtonClicked: () => void;
};

export interface ITagListPageProps {
    api: IApi,
    user: User,
    onPendoClick: () => void,
    showTitleAndCreateButton: boolean,
}

const TagListPage = forwardRef<CreateButtonRefHandler, ITagListPageProps>( (props, createButtonRef): JSX.Element => {

    // extreme hack to set the height of the DataGrid's ancestor
    // ideally should be replaced with a fix in the nav, but this might not happen
    const tableHeightRef = useRef<HTMLElement>(null)
    const [tableHeight, setTableHeight] = useState(0)
    const [guid, refresh] = useRandomGuid()
    const intl = useIntl()

    useEffect(() => {
        // window.onresize = refresh
        window.addEventListener('resize', () => {
            refresh()
        })
    }, [])
    useLayoutEffect(() => {
        const wrapperHeight = tableHeightRef.current?.parentElement?.getBoundingClientRect().height ?? 0
        const footerHeight = 32
        const createButtonDivHeight = 56
        const finalHeight = Math.max(0, wrapperHeight - footerHeight - createButtonDivHeight)
        setTableHeight(finalHeight)
    }, [tableHeightRef.current?.parentElement?.getBoundingClientRect(), guid])

    const [tagsFromApi, setTagsFromApi] = useState<PubTag[]>()
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [isLoadingFinished, setIsLoadingFinished] = useState(false)
    const {user, api} = props

    const [mount, openModal, closeModal] = useModal()
    const [alertMount, show, dismiss] = useToast()

    const memorializedFetchTags = useCallback(
        () => api.fetchTags(user.companyId)
        , [api.fetchTags, user.companyId])

    useEffect(() => {
        setErrorMessage('')
        setIsLoadingFinished(false)

        loadData(memorializedFetchTags, setTagsFromApi, setErrorMessage, api.logMessage)
            .catch(async (err: Error) => {
                // Will it ever get here? loadData swallows the error
                console.error("Error loading tags", err)
                await api.logMessage(err.message, user.companyId)
            }).finally(() => {
            setIsLoadingFinished(true)
        })
        // }, [memorializedFetchTags])
    }, [user.companyId])

    interface ISuccessMessageProps {
        theTag: FullPubTagWithOptionalFields
        tagId: number | undefined
        closeAlert: () => void
        successMessage: string
    }


    const SuccessMessage = ({theTag, tagId, closeAlert, successMessage}: ISuccessMessageProps): JSX.Element => {
        const closeAlertAndOpenGenerateCodeModal = (): void => {
            closeAlert()
            openModal(<GenerateCodeModal tagId={tagId} publisherId={theTag.publisherId} closeModal={closeModal}/>)
        }

        return <>
            <LinkDiv>
                <Text>{successMessage}</Text>
                <a onClick={() => closeAlertAndOpenGenerateCodeModal()}>
                    <UnderlinedText color="link">
                        {intl.formatMessage(content.tagListActionsGenerateCode)}
                    </UnderlinedText>
                </a>
            </LinkDiv>
        </>
    }

    const saveNewTag = async (tagFromModal: BasePubTag): Promise<void> => {

        const newPubTag: NewPubTag = {
            publisherId: user.companyId,
            ...tagFromModal
        }

        let newTag: ICreateTagFields = {tagId: undefined}
        try {
            newTag = await api.saveNewTag(newPubTag)
            // newTag.tagId = 1 // for testing without saving
        } catch (e) {
            if (e instanceof Error) {
                await api.logMessage(e.message, user.companyId)
                setErrorMessage(e.message)
            }
        }

        if (newTag.tagId !== undefined) {
            const showMessage: () => void = () => show({
                message: <SuccessMessage theTag={newPubTag} tagId={newTag.tagId} closeAlert={dismiss}
                                         successMessage={intl.formatMessage(content.tagListModalCreateMessage)}/>,
                contentStyle: {textAlign: "center", minWidth: "300px"},
                type: "success",
            })
            await loadData(memorializedFetchTags, setTagsFromApi, setErrorMessage, api.logMessage)
            showMessage()
        }
    }

    const saveTag = async (tag: FullPubTagWithOptionalFields): Promise<void> => {
        try {
            const showMessage: () => void = () => show({
                message: <Text>{intl.formatMessage(content.editPublisherTagSuccess)}</Text>,
                type: "success",
                duration: 15000,
                contentStyle: {textAlign: "center", minWidth: "300px"},
            })
            await props.api.saveTag(tag)
            await loadData(memorializedFetchTags, setTagsFromApi, setErrorMessage, api.logMessage)
            showMessage()
        } catch (e) {
            if (e instanceof Error) {
                await api.logMessage(e.message, user.companyId)
                setErrorMessage(e.message)
            }
        }
    }

    const openNewTagModal = () => openModal(<CreateTagModal user={user} saveTag={saveNewTag} closeModal={closeModal}
                                                            onLoadTagSection={OnLoadTagSection}/>)


    //Initialise your refs here
    useImperativeHandle(createButtonRef, () => ({
        createButtonClicked: openNewTagModal
    }));

    const blankSlate = (): JSX.Element => {
        return (
            <BlankSlate
                title={intl.formatMessage(content.tagBlankSlateMainDescription)}
            >
                <Description>
                    {intl.formatMessage(content.tagBlankSlateSubDescription)}
                    &nbsp;<a href="https://developers.cj.com/docs/publisher-site-tracking/publisher-tag-overview"
                             target="_blank" rel="noreferrer">{intl.formatMessage(content.tagLearnMore)}</a>
                </Description>
                {mount}
                {props.showTitleAndCreateButton ? <PrimaryActionButton
                    label={intl.formatMessage(content.tagListCreateTag)}
                    type="primary"
                    className="pt-create-tag-button"
                    handler={openNewTagModal}
                /> : ""}
            </BlankSlate>
        )
    }

    const tableWithData = (tags: PubTag[]): JSX.Element => {
        return (
            <Box refObject={tableHeightRef} direction='column' style={{ height: tableHeight }}>
                {props.showTitleAndCreateButton ?  <CreateButtonAboveTableStyleOverrides>
                                            <PrimaryActionButton
                                                label={intl.formatMessage(content.tagListCreateTag)}
                                                type="primary"
                                                className="pt-create-tag-button"
                                                handler={openNewTagModal}
                                            />
                                        </CreateButtonAboveTableStyleOverrides> : ""
                }
                <TagTable refreshHeight={refresh} tags={tags} saveTag={saveTag} user={user} openModal={openModal} closeModal={closeModal}
                    mount={mount} title={props.showTitleAndCreateButton ? intl.formatMessage(content.tagListTitle) : ""} />
                <AlertStyleOverrides>
                    <div>{alertMount}</div>
                </AlertStyleOverrides>
            </Box>
        )
    }

    const mainContent = (): JSX.Element => {
        if (errorMessage !== "") {
            return <><b>{errorMessage}</b></>
        }
        if (R.isNil(tagsFromApi) || tagsFromApi.length === 0) {
            return blankSlate()
        }
        // return blankSlate() // enable for testing
        return tableWithData(tagsFromApi)
    }

    return <>
        {
            isLoadingFinished ? mainContent() : <Loading/>
        }
    </>
})
TagListPage.displayName="TagListPage"

export default TagListPage
