import React, {FC, useState} from 'react'
import styled, {StyledComponent} from "styled-components"

import { Modal, ModalProps } from "@cjdev-internal/visual-stack-x/Modal"
import {Form, TextField} from "@cjdev-internal/visual-stack-x/Form"
import {Button} from "@cjdev-internal/visual-stack-x/Button"
import {Box} from "@cjdev-internal/visual-stack-x/Box"
// import { DIV } from "@cjdev-internal/visual-stack-x/Responsive"

import {useIntl} from "../utils/intl"
import content from "../utils/content"

import {IPubTagGridItem} from "../TagList/TagListPage"
import {User} from "../shared/User"
import { FullPubTagWithOptionalFields, OnLoadPubTag } from '@pubtag/tag-server'
import {IOnLoadTagSectionProps} from "./onLoad/OnLoadTagSection";
import AdvertiserSelector from "./onLoad/AdvertiserSelector";
import PromotionalProperties from "./onLoad/PromotionalProperties";

export interface IEditTagProps {
    user: User
    row: IPubTagGridItem
    closeModal: () => void
    saveTag: (tag: FullPubTagWithOptionalFields) => void
    onLoadTagSection: FC<IOnLoadTagSectionProps>
}

const StyledButton:StyledComponent<typeof Button,object> = styled(Button)`
    margin-right: 1.0em;
`

const EditTagModal = (props: IEditTagProps): JSX.Element => {

    const [modifiedTag, setModifiedTag] = useState(props.row.pubTag)
    const [errorMessage, setErrorMessage] = useState('')
    const intl = useIntl()

    const updateTagName = (newName:string): void => {
        setModifiedTag({...(modifiedTag), name: newName})
    }

    const canAccessOnLoadFeatures = modifiedTag.features.onLoad && props.user.hasAdditionalOnLoadFeatureAccess()

    const modalProps: ModalProps = {
        onBackgroundClick: props.closeModal,
        onEscapeKeyUp: props.closeModal,
        headerTitle: intl.formatMessage(content.editPublisherTag),
        footer:
            <>
                <StyledButton type="tertiary" onClick={() => props.closeModal()}>
                    {intl.formatMessage(content.tagModalCancel)}
                </StyledButton>
                <Button type="primary" htmlType="submit">
                    {intl.formatMessage(content.tagModalSave)}
                </Button>
            </>
    }

    return (
        <Form vertical={false}
              onSubmit={(e: React.ChangeEvent) => {
                  e.preventDefault()
                  try {
                      console.log('about to save tag: ', modifiedTag)
                      props.saveTag(modifiedTag)
                      props.closeModal()
                  } catch (error) {
                      setErrorMessage(intl.formatMessage(content.modalSaveError))
                  }
              }}>
            <Modal {...modalProps}>
                <Box paddingRight="medium-large" className="scrollable">
                    <Box paddingBottom="large" direction="column">
                        <label aria-label="tag type">{intl.formatMessage(content.modalTagTypeLabel)}</label>
                        <div aria-label="tag type">{modifiedTag.features.onLoad ? intl.formatMessage(content.onLoad) : intl.formatMessage(content.onClick) }</div>
                    </Box>

                    <TextField
                        required
                        autoFocus
                        value={modifiedTag.name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateTagName(e.target.value)}
                        label={intl.formatMessage(content.tagListTagName)}
                        aria-label="edit tag label"
                    />
                    {canAccessOnLoadFeatures ? (
                        <props.onLoadTagSection pubTag={modifiedTag as OnLoadPubTag} setPubTag={setModifiedTag}
                                                advertiserSelectorComponent={AdvertiserSelector}
                                                promotionalPropertiesComponent={PromotionalProperties}/>
                        ) : null
                    }
                    <div>{errorMessage}</div>
                </Box>
            </Modal>
        </Form>
    )
}

export default EditTagModal;