import PlatformContent from "./PlatformContent";
import React, {useRef} from "react";
import {IFeaturesDao} from "dao/FeaturesDao";
import {IApi} from "utils/api";
import content from "../../utils/content";
// @ts-ignore useUser is not an officially exported hook
import {NavRoute, User, useUser} from "@platform/cj-platform-navigation";
import {useIntl} from "../../utils/intl";
import {Button} from "@cjdev-internal/visual-stack-x/Button"
import {CreateButtonRefHandler} from "../../TagList/TagListPage";

export interface IContentProp {
    user: User
}

export const    staticRouteContent = {
    path: 'manage',
    layout: {
        dimensions: false,
        filters: false,
        sidenav: false,
        pageTitle: true,
        Buttons: true
    },
    titleMessage: {id: "pubtag.list.title", defaultMessage: "Publisher Tags"}
}

export const createPlatformRoutes = (
    TagListPage: any,
    featuresDao: IFeaturesDao,
    api: IApi,
    onPendoClick: () => void
): NavRoute[] => {
    const createButtonRef = useRef<CreateButtonRefHandler>(null)
    return [
        {
            ...staticRouteContent,
            Buttons: () => {
                const intl = useIntl()
                const newNavUser:User = useUser()
                const PUB_TAG_FEATURE_NAME = 'PUB_TAG_UI'
                const userFeatures: any = newNavUser.currentCompany.features
                const isPubTagEnabled = userFeatures?.has(PUB_TAG_FEATURE_NAME)
                return (
                    <div>
                        {
                            isPubTagEnabled ?
                                <Button
                                    type="primary"
                                    onClick={() => createButtonRef?.current?.createButtonClicked()}
                                >{intl.formatMessage(content.tagListCreateTag)}</Button> : ''
                        }
                    </div>
                )
            },
            Content: (contentProp: IContentProp) => {
                return <PlatformContent createButtonRef={createButtonRef}
                    TagListPage={TagListPage}
                    contentProp={contentProp}
                    api={api}
                    onPendoClick={onPendoClick}
                />
            }
        }
    ]
}
