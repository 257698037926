
export interface IAppConfig {
    memberUrl: string,
    ptApiUrl: string,
    loginRedirectUrl: string,
    userUrl: string,
    redirectBackToCjUrl: string,
    contactSupportUrl: string,
    pendoUrl: string,
    pagerDutyIntegrationUrl: string
}

const appConfig: IAppConfig = require(`./env/${process.env.REACT_APP_ENV || "local"}.json`);

export default appConfig;
