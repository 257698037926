
import {IAppConfig} from "../appConfig"

export interface IFeaturesDao {
    getFeatures(companyId: number): Promise<Set<string>>
}

export default class CompanyFeaturesDao implements IFeaturesDao {
    private _appConfig: IAppConfig;
    private readonly _fetch: WindowOrWorkerGlobalScope["fetch"];
    public constructor(appConfig: IAppConfig, fetch: WindowOrWorkerGlobalScope["fetch"]) {
        this._appConfig = appConfig;
        this._fetch = fetch;
    }

    public async getFeatures(companyId: number): Promise<Set<string>> {
        const requestUrl = `${this._appConfig.memberUrl}/affapi/company/${companyId}/features`

        const response = await this._fetch(requestUrl, {
            credentials: "omit"
        })

        if (response.status >= 400 && response.status < 600) {
            throw new Error(`Error retrieving Features for company ${companyId}. [${response.statusText}]`)
        }

        const enabledFeatures = await response.json() as string[]
        return new Set(enabledFeatures)
    }
}

