import * as R from "ramda"
import {createIntl, createIntlCache, IntlConfig, IntlShape} from "react-intl"
import {useEffect, useState} from 'react';
import en from "../content/en.json"
import cs from "../content/cs.json"
import de from "../content/de.json"
import es from "../content/es.json"
import fr from "../content/fr.json"
import ja from "../content/ja.json"
import pl from "../content/pl.json"
import pt from "../content/pt.json"
import zh from "../content/zh.json"
import {shouldDisplay2022Nav, useIntl as useNewNavIntl} from '@platform/cj-platform-navigation'

export const translations:any = {en, cs, de, es, fr, ja, pl, pt, zh}

const createReactIntl: (locale: string) => IntlShape = (locale: string) => {
    const cache = createIntlCache();
    const language = R.toLower(R.take(2, locale)).toString() // uses the navigator local, ex: en-US
    const defaultMessages = en
    const defaultLocale = "en-US"
    const translatedMessages = translations[language]
    const messages:  Record<string, string> = {...defaultMessages, ...translatedMessages}
    const config:IntlConfig = {
        locale: translatedMessages ? locale : defaultLocale,
        messages
    }
    return createIntl(
        config,
        cache)
}

const browserIntl: IntlShape = createReactIntl(navigator.language);

const useIntlToggle = (shouldDisplay2022Nav: () => Promise<boolean>, oldNavTranslations: IntlShape, useNewNavTranslations: () => IntlShape) => {
    const [translations, setTranslations] = useState<IntlShape>(oldNavTranslations);
    const newNavTranslations = useNewNavTranslations();

    useEffect(() => {
        shouldDisplay2022Nav().then((isNewNav) => {
            if (isNewNav) {
                setTranslations(newNavTranslations)
            } else {
                setTranslations(oldNavTranslations)
            }
        }).catch((error) => {
            setTranslations(oldNavTranslations)
            console.log("Error retrieving navigation feature toggle", error)
        })
    }, []);
    return translations;
};

// Custom hook that chooses translations based on new nav vs old nav
export const useIntl = () => useIntlToggle(shouldDisplay2022Nav, browserIntl, useNewNavIntl);

export const exportsForTesting = {
    useIntlToggle,
    createReactIntl
}