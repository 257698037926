import {Text} from "@cjdev-internal/visual-stack-x/Text";
import {Box} from "@cjdev-internal/visual-stack-x/Box"
import {ChoiceInput} from "@cjdev-internal/visual-stack-x/Form"
import {HoverPopover} from "@cjdev-internal/visual-stack-x/Popover"
import {useIntl} from "../utils/intl"
import content from "../utils/content"
import React, {useState} from "react"
import styled, {StyledComponent} from "styled-components"
import {FullPubTagWithOptionalFields} from '@pubtag/tag-server'
import "./styles.css"
import {defaultOnloadFeatures} from "./onLoad/OnLoadTagSection";
import {User} from "../shared/User";

const StyledPubTagChoiceDescription:StyledComponent<typeof Text,object> = styled(Text)`
    margin-left: 1.5em;
`
const UnderlinedText:StyledComponent<typeof Text,object> = styled(Text)`
    text-decoration: underline;
    margin-left: 0.2em;
`

interface ISelectTagTypeSectionProps {
    user: User,
    modifiedTag: FullPubTagWithOptionalFields,
    setModifiedTag: (tag: FullPubTagWithOptionalFields) => void,
    onLoadTagSection: React.ReactElement,
}

const SelectTagTypeSection = (props: ISelectTagTypeSectionProps): JSX.Element => {

    const [onLoadState, setOnLoadState] = useState(props.modifiedTag.features.onLoad)
    const intl = useIntl()

    const setTagType = (e: React.MouseEvent<HTMLButtonElement>): void => {
        const isOnLoadRadioClicked = e.currentTarget.value === 'on-load'
        if (isOnLoadRadioClicked !== onLoadState) {
            const {
                /* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
                pageBasedImpressionTracking,
                deepLinkAutomation,
                sids,
                dlaMyAdvertisersOnly,
                dlaExcludedAdvertisers,
                /* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
                ...standardFeatures
            } = props.modifiedTag.features
            const newFeatures = isOnLoadRadioClicked ? {...defaultOnloadFeatures, onLoad: true} : {
                ...standardFeatures,
                onLoad: false
            }
            const newTag = {
                ...(props.modifiedTag),
                features: newFeatures
            }

            props.setModifiedTag(newTag)
            setOnLoadState(newTag.features.onLoad)
        }
    }

    return (
        <>
            <Box paddingBottom="large" paddingTop="medium" direction="column">
                <Text aria-label="tagModalChooseTagTitle"
                      weight="medium">{intl.formatMessage(content.tagModalChooseTagTitle)}</Text>
                <Text aria-label="tagModalChooseTagDescription"
                      weight="light">{intl.formatMessage(content.tagModalChooseTagDescription)}&nbsp;
                    <Text color="link"><a href="https://developers.cj.com/docs/publisher-site-tracking/publisher-tag-overview" target="new"
                             aria-label="tagLearnMore">{intl.formatMessage(content.tagLearnMore)}</a></Text>
                </Text>
            </Box>
            <Box direction="column" gap="medium">
                <Box padding="large" direction="column" border>
                    <ChoiceInput
                        name="tag-type"
                        label={intl.formatMessage(content.modalTagTypeOnClick)}
                        value="on-click"
                        aria-label="On-Click"
                        checked={!(onLoadState)}
                        // @ts-ignore
                        onClick={setTagType}
                        onChange={() => {
                        }}
                    />
                    <StyledPubTagChoiceDescription aria-label="tagModalOnClickDescription" weight="light">
                        {intl.formatMessage(content.tagModalOnClickDescription)}
                        <HoverPopover
                            // aria-label="tagModalOnClickHoverExample"
                            wrapperProps={{ "aria-label": "tagModalOnClickHoverExample"}}
                            placement={'bottom'}
                            // shown={false}
                            content={
                                <Text weight="light">{intl.formatMessage(content.tagOnClickExampleDescription)}</Text>
                            }
                        >
                            <UnderlinedText color="link" aria-label="tagExample"
                                            weight="light"> {intl.formatMessage(content.tagExample)}</UnderlinedText>
                        </HoverPopover>
                    </StyledPubTagChoiceDescription>
                </Box>
                <Box padding="large"  direction="column" border>
                    <ChoiceInput
                        name="tag-type"
                        label={intl.formatMessage(content.modalTagTypeOnLoad)}
                        value="on-load"
                        aria-label="On-Load"
                        // wrapperProps={{ "aria-label": "On-Load"}}
                        checked={onLoadState}
                        // @ts-ignore
                        onClick={setTagType}
                        onChange={() => {
                        }}
                    />
                    <StyledPubTagChoiceDescription aria-label="tagModalOnLoadDescription" weight="light">
                        {intl.formatMessage(content.tagModalOnLoadDescription)}
                        <HoverPopover
                            // aria-label="tagModalOnLoadHoverExample"
                            wrapperProps={{ "aria-label": "tagModalOnLoadHoverExample"}}
                            placement={'bottom'}
                            // shown={false}
                            content={
                              <Text weight="light">{intl.formatMessage(content.tagOnLoadExampleDescription)}</Text>
                            }
                        >
                            <UnderlinedText aria-label="tagExample"
                                            weight="light" color="link">{intl.formatMessage(content.tagExample)}</UnderlinedText>
                        </HoverPopover>
                    </StyledPubTagChoiceDescription>
                    {onLoadState && props.user.hasAdditionalOnLoadFeatureAccess() ?
                        <Box paddingLeft="xl" direction="column">
                            {props.onLoadTagSection}
                        </Box>
                        : null
                    }
                </Box>
            </Box>
        </>
    );
}

export default SelectTagTypeSection
