import React from 'react'
import {createApi} from "./utils/api";
import Root from './Components/Root'
import Routes from './Components/Routes'

import {getAuthenticatedFetch} from "./shared/FetchService"
import AuthService from "./shared/AuthService";
import CompanyFeaturesDao from "./dao/FeaturesDao";
import {User} from "./shared/User";
import {useDataFetcher} from "./hooks/dataFetcher";

import {PlatformApp, shouldDisplay2022Nav} from '@platform/cj-platform-navigation'

import appConfig from "./appConfig"
import NavigationToggle from "./Components/NewNavigation/NavigationToggle";
import '@cjdev-internal/design-tokens';
import TagListPage from "./TagList/TagListPage";
import createNewNavigation from "./Components/NewNavigation/NewNavigation";

import {useIntl} from "./utils/intl"
import content from "./utils/content"

const Dependencies = (): JSX.Element => {

    const authService = new AuthService()
    const authFetch = getAuthenticatedFetch(authService, window.fetch)
    const featuresDao = new CompanyFeaturesDao(appConfig, authFetch)

    const productionApi = createApi(window.fetch, appConfig, authService)

    const legacyUser = new User()
    const result = useDataFetcher(
        () => {
            console.log('fetching features')
            return featuresDao.getFeatures(legacyUser.companyId)
        }, new Set(), [legacyUser.companyId]
    );
    legacyUser.features = result.data

    const goToPendo: () => void = () => {
        if (appConfig.pendoUrl) {
            window.open(appConfig.pendoUrl, '_blank')
        } else {
            alert('no pendo url provided');
        }
    }
    const tagListPage = <TagListPage
        api={productionApi}
        user={legacyUser}
        showTitleAndCreateButton={true}
        onPendoClick={goToPendo}/>

    const intl = useIntl()

    const navActions = {
        back: {
            content: intl.formatMessage(content.backToCjam),
            onClick: () => window.open(appConfig.redirectBackToCjUrl, '_top')
        },
        help: () => goToPendo()
    }

    const RootImpl = (): JSX.Element => <Root productionApi={productionApi}
                                              navActions={navActions}
                                              tagListPage={tagListPage}/>

    const OldNavigation = (): JSX.Element => <Routes root={RootImpl}/>

    const NewNavigation = createNewNavigation(TagListPage, PlatformApp, featuresDao,  productionApi, goToPendo)

    const Navigation = (): JSX.Element => <NavigationToggle OldNavigation={OldNavigation}
                                                            NewNavigation={NewNavigation}
                                                            shouldDisplay2022Nav={shouldDisplay2022Nav}
                                                            api={productionApi}/>
    return <Navigation/>
}

export default Dependencies
