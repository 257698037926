import "regenerator-runtime";
import {IAuthConfig} from "../../Login/authConfig";
import {
    getBearerToken as gbt,
    getUserId as gui,
    handleOAuthCallback as hoac,
    initAuth as ia
} from "@platform/platform-auth";

export async function getBearerToken(): Promise<string | undefined> {
    return gbt()
}

export async function getUserId(): Promise<string> {
    return gui()
}

export async function handleOAuthCallback(): Promise<void> {
    return hoac()
}

export async function initAuth(config: IAuthConfig): Promise<void> {
    return ia(config)
}

export default class AuthService {
    public async getBearerToken(): Promise<string | undefined> {
        return gbt()
    }

    public async getUserId(): Promise<string> {
        return gui()
    }

    public async handleOAuthCallback(): Promise<void> {
        return hoac()
    }

    public async initAuth(config: IAuthConfig): Promise<void> {
        return ia(config)
    }

}
