import {FullPubTagWithOptionalFields, NewPubTag, PubTag} from '@pubtag/tag-server'
import {IAppConfig} from "../appConfig";
import AuthService from "../shared/AuthService";

export interface IUser {
    firstName?: string
    lastName?: string
    emailAddress?: string
    isEmployee?: boolean
    companies: {
        id: string
        type: "advertiser" | "publisher"
        name?: string
        status?: "ACTIVE" | string
        functionalCurrency?: string
        companyRole?: string
    }[]
}

export interface IPendoUser {
    id?: string
    emailAddress?: string
    isEmployee?: boolean
}

export interface ICreateTagFields {
    tagId: number | undefined
}

export interface IApi {
    fetchTags: (publisherId: number) => Promise<FullPubTagWithOptionalFields[]>
    saveNewTag: (pubTag: NewPubTag) => Promise<ICreateTagFields>
    saveTag: (pubTag: FullPubTagWithOptionalFields) => Promise<void>
    fetchWithAuthBearerToken: <ResponseJson extends object>(url: string, token: string) => Promise<ResponseJson>
    getUser: () => Promise<IPendoUser>
    logMessage : (message:string, companyId?:number) => Promise<void>
}

export function createApi(
    fetchFunction: (input: RequestInfo, init?: RequestInit) => Promise<Response>,
    appConfig: IAppConfig,
    authService: AuthService): IApi {


    const pubTagApiBaseUrl = appConfig.ptApiUrl
    const userUrl = appConfig.userUrl

    const fetchWithAuthBearerToken = async <ResponseJson extends object>(url: string, token: string): Promise<ResponseJson> => {
        const response = await fetchFunction(url, {
            mode: 'cors',
            headers: {
                authorization: `Bearer ${token}`
            }
        })
        if (response.ok) {
            return response.json()
        }
        throw new Error(`bad fetchWithAuthBearerToken response: ${response.status} response.url: ${url}`)
    }


    const postWithAuthBearerToken = async <ResponseJson extends object>(url: string, token: string, payload: object): Promise<ResponseJson> => {
        const response = await fetchFunction(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                authorization: `Bearer ${token}`
            },
            body: JSON.stringify(payload)
        })

        if (response.ok) {
            return response.json()
        }
        throw new Error(`bad post. response.status: ${response.status} response.url: ${response.url}`)
    }

    const fetchTags = async (publisherId: number): Promise<PubTag[]> => {
        const url = `${pubTagApiBaseUrl}/publisher/${publisherId}/tags`
        const token = await authService.getBearerToken() as string
        return fetchWithAuthBearerToken(url, token)
    }

    const saveTag = async (tag: FullPubTagWithOptionalFields): Promise<void> => {
        const url = `${pubTagApiBaseUrl}/publisher/${tag.publisherId}/tag/${tag.tagId}`
        const token = await authService.getBearerToken() as string
        await postWithAuthBearerToken(url, token, tag)
    }

    const saveNewTag = async (pubTag: NewPubTag): Promise<ICreateTagFields> => {
        const url = `${pubTagApiBaseUrl}/publisher/${pubTag.publisherId}/tag`
        const token = await authService.getBearerToken() as string
        return postWithAuthBearerToken(url, token, pubTag)
    }

    const fetchUser = async (userId: string): Promise<IPendoUser> => {
        const token = await authService.getBearerToken() as string
        return await fetchWithAuthBearerToken(
            `${userUrl}/${userId}?excludeLinkUsers=1&includeActiveOnlyForBackwardsCompatibility=false`,
            token
        )
    }

    const getUserById = async (userId: string): Promise<IPendoUser> => {
        const userData = await fetchUser(userId)
        return {
            id: userId,
            emailAddress: userData.emailAddress,
            isEmployee: userData.isEmployee
        }
    }

    const getUser = async (): Promise<IPendoUser> => {
        return getUserById(await authService.getUserId())
    }

    const logMessage = async (message: string, companyId?:number): Promise<void> => {
        const url = `${pubTagApiBaseUrl}/logmessage/${companyId}`
        const token = await authService.getBearerToken() as string
        try {
            await postWithAuthBearerToken(url, token, {message:message})
        } catch (error:any) {
            // We want to swallow this...
            console.error('Error logging message to server. {error.message, message}.', {errorMessage: error.message, message: message})
        }
    }

    return {
        fetchTags,
        saveNewTag,
        saveTag,
        fetchWithAuthBearerToken,
        getUser,
        logMessage
    }
}

