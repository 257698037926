import {ApolloClient, HttpLink, InMemoryCache} from "@apollo/client";
import {getBearerToken} from '../shared/AuthService'
import * as R from "ramda"
import {getConfigFromEnv, IConfig} from "./config"

const config: IConfig = getConfigFromEnv(process.env.REACT_APP_ENV)

const redirectToLogin = (): void => {
    window.location.href = `${config.loginRedirectUrl}`
}

const isUnauthenticated = async (response: Response): Promise<boolean> => {
    if (response.status === 401) return true
    try {
        const json = await response.clone().json()
        return json.errors && json.errors[0].extensions?.code === "UNAUTHENTICATED"
    } catch (e) {
        return false
    }
}

export const authenticatedFetch = async (
    uri: string,
    options = {},
    redirectOnUnauthenticated = true
): Promise<Response> => {
    const bearerToken = await getBearerToken();
    const optionsWithToken = R.mergeDeepRight(options, {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        },
    });
    const response = await fetch(uri, optionsWithToken)

    if (redirectOnUnauthenticated && (await isUnauthenticated(response))) {
        redirectToLogin()
        throw new Error("Unauthenticated")
    }

    if (!response.ok) {
        throw new Error(await response.text())
    }

    return response
}


export const accountsClient: ApolloClient<object> = new ApolloClient({
    cache: new InMemoryCache(),
    // uri: config.promotionalPropertiesUrl,
    link: new HttpLink({
        uri: config.promotionalPropertiesUrl,
        fetch: authenticatedFetch,
    }),
})
