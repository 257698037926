export interface IAuthConfig {
    clientId: string,
    redirectUri: string,
}

const authConfig: () => IAuthConfig = () => {
    const prod = {
        "clientId": "kAwxeD61C1Udh0x1g2qgSpHvvJN3QXoP",
        "redirectUri": "https://pubtag.cj.com/oauth-callback"
    }

    const lab = {
        "clientId": "kAwxeD61C1Udh0x1g2qgSpHvvJN3QXoP",
        "redirectUri": "https://pubtag.dev.cj.com/oauth-callback"
    }

    const local = {
        "clientId": "kAwxeD61C1Udh0x1g2qgSpHvvJN3QXoP",
        "redirectUri": "https://local.cj.com:3000/oauth-callback"
    }

  const hostname = window.location.hostname;

  if (hostname.includes("local")) {
    return local;
  }

  if (hostname.includes("ads-staging.cjpowered.com") || hostname.includes("pubtag.dev.cj.com")) {
    return lab;
  }

  if (hostname.includes("pubtag.cjpowered.com") || hostname.includes("pubtag.cj.com")) {
    return prod;
  }

  if (hostname.includes("cloudfront.net")) {
      console.error("You probably don't want to be hitting cloudfront directly but using lab config")
      return lab;
  }

  throw Error("No oauth configuration found for this host: " + hostname)

}

export default authConfig
