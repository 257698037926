import {Pendo} from "./Pendo";
import {TopNavLayout} from "@cjdev-internal/visual-stack-x/TopNavLayout"
import {useIntl} from "../utils/intl";
import content from "../utils/content";
import React from "react";
import {IApi} from "../utils/api";

interface IRootProps {
    productionApi: IApi,
    navActions: object,
    tagListPage: JSX.Element
}

const Root = ({productionApi, navActions, tagListPage}: IRootProps): JSX.Element => {
    const intl = useIntl()
    return (
        <div>
            <Pendo api={productionApi}/>
            <TopNavLayout
                title={intl.formatMessage(content.appTitle)}
                actions={navActions}
            >
                {tagListPage}
            </TopNavLayout>
        </div>
    )

}

export default Root


