import React, {useEffect, useState} from "react";
import {IApi} from "../../utils/api";

interface INavigationToggleProps {
    NewNavigation: React.ComponentType,
    OldNavigation: React.ComponentType,
    shouldDisplay2022Nav: () => Promise<boolean>,
    api: IApi
}

enum NavDisplayState {
  Loading = 'loading',
  OldNav = 'oldnav',
  NewNav = 'newnav'
}

const NavigationToggle = ({
                                NewNavigation, //eslint-disable-line @typescript-eslint/naming-convention
                                OldNavigation, //eslint-disable-line @typescript-eslint/naming-convention
                                shouldDisplay2022Nav,
                                api
                            }: INavigationToggleProps): JSX.Element => {
    const [navDisplayState, setNavDisplayState] = useState<NavDisplayState>(NavDisplayState.Loading)

    useEffect(() => {
      shouldDisplay2022Nav().then(showNav => {
        setNavDisplayState(showNav ? NavDisplayState.NewNav : NavDisplayState.OldNav)
      }).catch(async (err) => {
        console.error("Error showing platform navigation", err)
        await api.logMessage(err.message)
      });
    }, [])

    if (navDisplayState === NavDisplayState.OldNav) return <OldNavigation />
    else if (navDisplayState === NavDisplayState.NewNav) return <NewNavigation />
    else return <div />
}
export default NavigationToggle
