import PendoLibrary from "@cjdev-internal/pendo/dist/pendo.min.js"
import React, {useEffect} from "react"
import {IApi} from "../utils/api"

type Env = "development" | "production" | "test"
const env: Env = process.env.NODE_ENV

interface IPendoProps {
    api: IApi
}

interface IPendoInitializer {
    initialize(userId: string | undefined, email: string | undefined, isInternal: boolean | undefined): void;
}

const install: (env: string) => IPendoInitializer = PendoLibrary.install

export const Pendo = (props: IPendoProps): JSX.Element => {
    useEffect(() => {
        const getUser = async (): Promise<void> => {
            const userData = await props.api.getUser()
            const initializer = install(env);
            const userId = userData.id
            const email = userData.emailAddress
            const isInternal = userData.isEmployee
            initializer.initialize(userId, email, isInternal)
        }
        getUser().catch(async (err) => {
            console.error("Error getting pendo user", err)
            await props.api.logMessage(err.message)
        })
    }, [props])
    return <div></div>
}
