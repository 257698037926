import React, {useState} from 'react'
import styled, {StyledComponent} from "styled-components"

import { Modal, ModalProps } from "@cjdev-internal/visual-stack-x/Modal"
import {Form, TextField} from "@cjdev-internal/visual-stack-x/Form"
import {Text} from "@cjdev-internal/visual-stack-x/Text"
import {Button} from "@cjdev-internal/visual-stack-x/Button"
import {Box} from "@cjdev-internal/visual-stack-x/Box"

import {useIntl} from "../utils/intl";
import content from "../utils/content";

import {User} from "../shared/User"
import { FullPubTagWithOptionalFields, OnLoadPubTag } from '@pubtag/tag-server'
import SelectTagTypeSection from "./SelectTagTypeSection";
import PromotionalProperties from "./onLoad/PromotionalProperties";
import AdvertiserSelector from "./onLoad/AdvertiserSelector";
import {IOnLoadTagSectionProps} from "./onLoad/OnLoadTagSection";

export interface ICreateTagProps {
    user: User
    closeModal: () => void
    saveTag: (tag: FullPubTagWithOptionalFields) => void
    onLoadTagSection: (props: IOnLoadTagSectionProps) => JSX.Element
}

const StyledButton:StyledComponent<typeof Button,object> = styled(Button)`
    margin-right: 1.0em;
`

const CreateTagModal = (props: ICreateTagProps): JSX.Element => {

    const defaultTag : FullPubTagWithOptionalFields = {
        publisherId: props.user.companyId,
        features: {bounceless: true, consentModule: true, onLoad: false},
        name: "",
    }

    const [modifiedTag, setModifiedTag] = useState(defaultTag)
    const [errorMessage, setErrorMessage] = useState('')
    const intl = useIntl()

    const onLoadTagSection = <props.onLoadTagSection
        pubTag={modifiedTag as OnLoadPubTag}
        setPubTag={setModifiedTag}
        promotionalPropertiesComponent={PromotionalProperties}
        advertiserSelectorComponent={AdvertiserSelector}
    />

    const modalProps: ModalProps = {
        onBackgroundClick: props.closeModal,
        onEscapeKeyUp: props.closeModal,
        headerTitle: intl.formatMessage(content.tagListModalTitle),
        footer:
            <>
                <StyledButton type="tertiary" onClick={() => props.closeModal()}>
                    {intl.formatMessage(content.tagModalCancel)}
                </StyledButton>
                <Button type="primary" htmlType="submit">
                    {intl.formatMessage(content.tagModalCreate)}
                </Button>
            </>
    }

    return (
        <Form vertical={false}
              onSubmit={(e: React.ChangeEvent) => {
                  e.preventDefault()
                  try {
                      console.log('about to save tag: ', modifiedTag)
                      props.saveTag(modifiedTag)
                      props.closeModal()
                  } catch (error) {
                      setErrorMessage(intl.formatMessage(content.modalSaveError))
                  }
              }}>

            <Modal className="modal-variant" {...modalProps}>
                <Box paddingRight="medium-large" className="scrollable">
                <TextField
                    required
                    autoFocus
                    value={modifiedTag.name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setModifiedTag({...modifiedTag, name: e.target.value})}
                    label={intl.formatMessage(content.tagListTagName)}
                    aria-label="tagListTagName"
                    name="tagName"
                />
                <div>{errorMessage}</div>
                <Text weight="light">{intl.formatMessage(content.modalTagNameInfo)}</Text>
                <SelectTagTypeSection user={props.user} modifiedTag={modifiedTag} setModifiedTag={setModifiedTag} onLoadTagSection={onLoadTagSection}/>
                </Box>
            </Modal>
        </Form>
    )
}

export default CreateTagModal;