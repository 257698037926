import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import PrivateRoute from "../Login/PrivateRoute";

interface IRoutesProps {
    root: React.ComponentType
}

const Routes = ({root}: IRoutesProps): JSX.Element =>
    <Router>
        <Switch>
            <Route exact path={"/"} component={() => <Redirect to="/manage"/>}/>
            <PrivateRoute path={"/manage"} component={root}/>
            <Route exact path={"/oauth-callback"} component={() => <Redirect to="/manage"/>}/>
        </Switch>
    </Router>

export default Routes

