import React from 'react';
import ReactDOM from 'react-dom';
import Dependencies from './Dependencies';
import * as serviceWorker from './serviceWorker';
import {initAuth} from "./shared/AuthService";
import authConfig from '../src/Login/authConfig'
import {Provider} from "./Components/Provider";

initAuth(authConfig()).then(async () => {
    ReactDOM.render(
        <Provider>
            <Dependencies/>
        </Provider>
    , document.getElementById('root'))
}).catch((err) => {
    console.error("Error initializing authentication", err)
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
