import {User} from "../../shared/User";
import React, {useEffect, useState} from "react";
import {IContentProp} from "./PlatformRoutes";
import {IApi} from "../../utils/api";
import {ErrorPage} from "@cjdev-internal/visual-stack-x/ErrorPage"
import appConfig from "../../appConfig";

export interface IPlatformContentProps {
    createButtonRef?: any,
    TagListPage: any,
    contentProp: IContentProp,
    api: IApi,
    onPendoClick: () => void
}

const PlatformContent = ({
                             createButtonRef,
                             TagListPage,
                             contentProp,
                             api,
                             onPendoClick,
                         }: IPlatformContentProps): JSX.Element => {
    const [user, setUser] = useState<User>(new User());
    const [isAdvertiser, setIsAdvertiser] = useState<boolean>(false);
    const PUB_TAG_FEATURE_NAME = 'PUB_TAG_UI'

    useEffect(() => {
        const currentCompany = contentProp.user.currentCompany
        if (currentCompany.type === "advertiser") {
            setIsAdvertiser(true)
        } else {
            const userFeatures: any = currentCompany.features
            if(isAdvertiser)
                setIsAdvertiser(false)
            const newUser = new User()
            newUser.companyId = currentCompany.id
            newUser.features = userFeatures
            setUser(newUser)
        }
    }, [contentProp.user.currentCompany])

    if (isAdvertiser) {
        return (<div>
            <ErrorPage
                errorCode={403}
                onClickBack={() => window.open(appConfig.redirectBackToCjUrl, '_top')}
                onClickSupport={() => window.open(appConfig.contactSupportUrl, '_top')}
            />
        </div>)
    }
    if(!user?.features?.has(PUB_TAG_FEATURE_NAME)) {
        return (<div>
            <ErrorPage
                errorCode={403}
                onClickBack={() => window.open(appConfig.redirectBackToCjUrl, '_top')}
                onClickSupport={() => window.open(appConfig.contactSupportUrl, '_top')}
            />
        </div>)
    }
    return (
            <TagListPage ref={createButtonRef} api={api} user={user} onPendoClick={onPendoClick} showTitleAndCreateButton={false}/>
    )
}

export default PlatformContent
