import {useQuery, UseQueryResult} from "react-query"
import {authenticatedFetch} from "../utils/clients"
import {getConfigFromEnv} from "../utils/config"
import {IAdvertiser, IAdvertiserQueryCriteria} from "../utils/types"

const useApi: boolean = true

interface IAdvertisers {
    "advertisers": IAdvertiser[]
}

const allData: IAdvertisers  = {
    "advertisers":
        [
            {
                "cid": 3419408,
                "name": "ClickMeeting Sp.z.o.o",
                "websiteName": "ClickMeeting",
                "enterpriseId": 1521061,
                "status": "ACTIVE"
            },
            {
                "cid": 3609731,
                "name": "DL1961 Premium Denim",
                "websiteName": "DL1961 Women",
                "enterpriseId": 1522798,
                "status": "ACTIVE"
            },
            {"cid": 5211980, "name": "Ellos", "websiteName": "Ellos", "enterpriseId": 1554556, "status": "ACTIVE"},
            {
                "cid": 5212035,
                "name": "Kork-Ease",
                "websiteName": "Kork-Ease",
                "enterpriseId": 1556836,
                "status": "ACTIVE"
            },
            {
                "cid": 849384,
                "name": "AutoBarn.com, Inc.",
                "websiteName": "AutoBarn.com",
                "enterpriseId": 849384,
                "status": "ACTIVE"
            },
            {
                "cid": 867296,
                "name": "Restaurant.com",
                "websiteName": "Restaurant.com",
                "enterpriseId": 867296,
                "status": "ACTIVE"
            },
            {
                "cid": 953659,
                "name": "Andrell International, LLC",
                "websiteName": "eVitamins",
                "enterpriseId": 953659,
                "status": "ACTIVE"
            },
            {
                "cid": 1919535,
                "name": "PE Digital GmbH",
                "websiteName": "PARSHIP - find someone, find the love of your life",
                "enterpriseId": 1502193,
                "status": "ACTIVE"
            },
            {
                "cid": 2210176,
                "name": "Argos",
                "websiteName": "Argos Pet Insurance",
                "enterpriseId": 1505810,
                "status": "ACTIVE"
            },
            {
                "cid": 1815767,
                "name": "Robbins Research International",
                "websiteName": "Tony Robbins",
                "enterpriseId": 1501730,
                "status": "ACTIVE"
            },
            {
                "cid": 1876160,
                "name": "Insplanet Forsakringsformedling AB",
                "websiteName": "Insplanet Sweden",
                "enterpriseId": 1501986,
                "status": "ACTIVE"
            },
            {"cid": 2125713, "name": "ssense.com", "websiteName": "SSENSE", "enterpriseId": 1504164, "status": "ACTIVE"}
        ]
}

const joinedData: IAdvertisers = {
    "advertisers": [
        {
            "cid": 3981075,
            "name": "Gizzmoheaven",
            "websiteName": "Gizzmoheaven PriceRunner ROI",
            "enterpriseId": 1527252,
            "status": "ACTIVE"
        },
        {"cid": 4487634, "name": "IDrive", "websiteName": "IDrive", "enterpriseId": 1532915, "status": "ACTIVE"}
    ]
}

const fetchAdvertisers = (criteria: IAdvertiserQueryCriteria | undefined): () => Promise<IAdvertiser[]> => async () => {

    if (!criteria) {
        return Promise.resolve({"advertisers": []})
    }
    // console.log('criteria: '+JSON.stringify(criteria))
    const {publisherId, joinedOrAll} = criteria
    const config = getConfigFromEnv(process.env.REACT_APP_ENV)
    const advertiserQuerySelect = joinedOrAll || 'all'
    const advertiserUrl = `${config.advertisersUrl}/${publisherId}/${advertiserQuerySelect}-advertisers`

    if (!useApi)
        return Promise.resolve(advertiserQuerySelect === 'all' ? allData : joinedData)

    const response = await authenticatedFetch(advertiserUrl)
    return response.json()
}

export const useAdvertisers = (criteria: IAdvertiserQueryCriteria | undefined): UseQueryResult<IAdvertisers> => {
    return useQuery(
        ["fetch advertisers", criteria],
        fetchAdvertisers(criteria),
        {retry: false}
    )
}
