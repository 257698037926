import {IFeaturesDao} from "../../dao/FeaturesDao";
import {IApi} from "../../utils/api";
import {createPlatformRoutes} from "./PlatformRoutes";
import React, {FC} from "react";
import {translations} from "../../utils/intl"

const createNewNavigation = (TagListPage: any,
                             PlatformApp: any,
                             featuresDao: IFeaturesDao,
                             api: IApi,
                             onPendoClick: () => void): FC => {
    const platformRoutes = createPlatformRoutes(TagListPage, featuresDao, api, onPendoClick);
    const NewNavigation = (): JSX.Element => <PlatformApp routes={platformRoutes} translations={translations}/>
    return NewNavigation
};
export default createNewNavigation