import {DependencyList, useEffect, useState} from "react"

interface  IFetchResult<T> {
    data: T,
    fetching: boolean,
    error: Error | undefined
}

export function useDataFetcher<T>(
    fetcher: () => Promise<T>,
    initialState: T,
    deps: DependencyList = []): IFetchResult<T> {

    const [data, setData] = useState(initialState)
    const [fetching, setFetching] = useState(true)
    const [error, setError] = useState<Error | undefined>(undefined)

    useEffect(() => {
        const innerAsyncFetcher = async ():Promise<void> => {
            try {
                setFetching(true);
                const data = await fetcher();
                setData(data);
                setFetching(false);
            } catch (error) {
                if(error instanceof Error){
                    setError(error);
                }
                setFetching(false);
            }
        }

        innerAsyncFetcher().catch((error) => {
            setError(error);
            setFetching(false);
        })

    }, deps)

    return {data, fetching, error}
}
