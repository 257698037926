import {Box} from "@cjdev-internal/visual-stack-x/Box"
import {CreatableSelect} from "@cjdev-internal/visual-stack-x/CreatableSelect"

import {useIntl} from "../../utils/intl"
import content from "../../utils/content"

import {DocumentNode, gql, useQuery} from "@apollo/client"
import {IPromoPropertiesProps} from "../../utils/types"
import React from 'react'

export const GET_PROMO_PROPS: DocumentNode = gql`
    query promotionalProperties($publisherId: ID!) {
        promotionalProperties(publisherId: $publisherId, status: ACTIVE) {
            resultList {
                id
                name
            }
        }
    }`

export const PromotionalProperties = (props: IPromoPropertiesProps): JSX.Element => {

    interface IPromoProperty {
        id: string,
        name: string
    }
    const intl = useIntl()

    const publisherId = props.publisherId

    const {data, error} = useQuery(GET_PROMO_PROPS, {
        variables: {publisherId},
    })

    if (error) {
        return <span className="errors">{intl.formatMessage(content.tagModalError)}: {error}</span>
    }

    const fetchedPromoProps = data ? data.promotionalProperties.resultList : []

    const promoProperties = fetchedPromoProps.map((o: IPromoProperty) => {
        return {
            value: Number(o.id),
            label: `${o.name} - ${o.id}`
        }
    })

    return (
        <Box paddingBottom="small" direction="column" >
            <Box className="promoListLabel" direction="row">
                <label>{intl.formatMessage(content.modalTagPromoListLabel)}</label>
                <span className="form-group-required-sign">*</span>
            </Box>
            <CreatableSelect
                value={promoProperties.filter((item: { value: number } ) => item.value === props.promotionalPropertyId)}
                data-testid="promo-select"
                placeholder={intl.formatMessage(content.selectFromList)}
                className="promotional-property-selector"
                options={promoProperties}
                onChange={(e: {value:number}) => {props.updatePromoProperty(e.value)}}
                paddingTop="small"
            />
            <input // This handles the required-ness of the Select above.
                tabIndex={-1}
                autoComplete="off"
                style={{
                    opacity: 0,
                    width: "100%",
                    height: 0,
                }}
                onChange={() => {
                }}
                value={props.promotionalPropertyId}
                required
            />
        </Box>
    )
}

export default PromotionalProperties
